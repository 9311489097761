
import './App.css';
import omor from './Clean omor.gif'
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={omor} className="App-logo" alt="I omor" />
        <p>
         Hey there! Welcome to my website. I am Jesse
        </p>
        <p>
I'm currently studying at the university of Toronto.
I am specializing in neuroscience, majoring in psychology and minoring in chemistry.        </p>
        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
    </div>
  );
}

export default App;
